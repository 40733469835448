import React from "react";
import { NavLink } from "react-router-dom";
import UserService from "../../services/UserService";

import "../../scss/NavBar.scss";

const ConnectNavItems = () => {
  return (
    <>
      <div
        className="nav-item p-2"
        data-te-nav-item-ref
        data-te-offcanvas-dismiss
      >
        <NavLink className="nav-link" to="/">
          Home
        </NavLink>
      </div>
      <div
        className="nav-item p-2"
        data-te-nav-item-ref
        data-te-offcanvas-dismiss
      >
        <NavLink className="nav-link" to="/accounts">
          View Accounts
        </NavLink>
      </div>
      <div
        className="nav-item p-2"
        data-te-nav-item-ref
        data-te-offcanvas-dismiss
      >
        <NavLink className="nav-link" to="/documents">
          Documents
        </NavLink>
      </div>
      <div
        className="nav-item p-2"
        data-te-nav-item-ref
        data-te-offcanvas-dismiss
      >
        <NavLink className="nav-link" to="/forms">
          Forms
        </NavLink>
      </div>
      <div
        className="nav-item p-2"
        data-te-nav-item-ref
        data-te-offcanvas-dismiss
      >
        <NavLink className="nav-link" to="/benefits">
          Benefits
        </NavLink>
      </div>
      <div
        className="nav-item p-2"
        data-te-nav-item-ref
        data-te-offcanvas-dismiss
      >
        <NavLink className="nav-link" to="/faqs">
          Help Centre
        </NavLink>
      </div>
      <div className="nav-item p-2 relative">
        <NavLink className="nav-link" to="/contact-us">
          Contact Us
        </NavLink>
      </div>
    </>
  );
};

const IntroducerNavItems = (props) => {
  return (
    <>
      {props?.userInfo?.preferred_username === 'stieg_larsson_test' || UserService.isAggregator() ?
        <div
          className="nav-item py-2 px-4 hub-link"
          data-te-nav-item-ref
          data-te-offcanvas-dismiss
        >
          <NavLink className="nav-link" to="/learning-hub">
            Learning Hub
          </NavLink>
        </div>
        :
        <>
          <div
            className="nav-item p-2"
            data-te-nav-item-ref
            data-te-offcanvas-dismiss
          >
            <NavLink className="nav-link" to="/applications">
              Applications
            </NavLink>
          </div>
          <div
            className="nav-item p-2"
            data-te-nav-item-ref
            data-te-offcanvas-dismiss
          >
            <NavLink className="nav-link" to="/portfolios">
              Portfolios
            </NavLink>
          </div>
          <div
            className="nav-item p-2"
            data-te-nav-item-ref
            data-te-offcanvas-dismiss
          >
            <NavLink className="nav-link" to="/resources">
              Resources
            </NavLink>
          </div>
          <div
            className="nav-item p-2"
            data-te-nav-item-ref
            data-te-offcanvas-dismiss
          >
            <NavLink className="nav-link" to="/benefits">
              Benefits
            </NavLink>
          </div>
          <div
            className="nav-item py-2 px-4 hub-link"
            data-te-nav-item-ref
            data-te-offcanvas-dismiss
          >
            <NavLink className="nav-link" to="/learning-hub">
              Learning Hub
            </NavLink>
          </div>
        </>
      }
    </>
  );
};

const SolicitorNavItems = () => {
  return (
    <>
      <div
        className="nav-item p-2"
        data-te-nav-item-ref
        data-te-offcanvas-dismiss
      >
        <NavLink className="nav-link" to="/">
          New Loans
        </NavLink>

      </div>
      <div
        className="nav-item p-2"
        data-te-nav-item-ref
        data-te-offcanvas-dismiss
      >
        <NavLink className="nav-link" to="/discharges">
          Discharges
        </NavLink>

      </div>
    </>
  );
};

const RZNavItems = () => {
  return (
    <>
      <div
        className="nav-item py-2 px-4"
        data-te-nav-item-ref
        data-te-offcanvas-dismiss
      >
        <NavLink className="nav-link dropdown-item" to="/manage-users">
          Manage Users
        </NavLink>
      </div>
    </>
  );
};

export default function NavBarItems(props) {
  return (
    <div
      className="navbar-nav mr-auto flex flex-col pl-0 lg:flex-row"
      data-te-navbar-nav-ref
    >

      {UserService.isCustomer() ? ConnectNavItems() : null}
      {(UserService.isIntroducer() || UserService.isBDM()) && !UserService.isClientServices(props) ? IntroducerNavItems(props) : null}
      {UserService.isSolicitor() ? SolicitorNavItems(props) : null}
      {UserService.isClientServices() ? RZNavItems(props) : null}
    </div>
  );
}
